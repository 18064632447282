import {
  Box,
  Chip,
  Grid,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import React from 'react';
import {
  removeDownloadStation,
  selectCurrentStation,
  selectDownloadStations,
  setIntervalValue,
  stationStateRoot,
} from '../../redux/features/stationSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import ConfirmDownload from './ConfirmDownload';
import DataFields from './DataFields';
import DatePicker from './DatePicker';

import ToolTipLabel from './TooltipLabel';
import InfoIcon from '@mui/icons-material/Info';
import dayjs from 'dayjs';

interface FiltersFormProps {}

type SelectedStation = {
  station_id: number;
  name: string;
  sta: string;
};

export type SelectedStations = SelectedStation[];

const FiltersForm: React.FunctionComponent<FiltersFormProps> = (props) => {
  const selectedStations = useAppSelector(selectDownloadStations);
  const currentStation = useAppSelector(selectCurrentStation);
  const stateRootStation = useAppSelector(stationStateRoot);
  const dispatch = useAppDispatch();
  //@ts-ignore
  const { dateFrom, dateTo } = currentStation?.properties || {};
  let minValue = dateFrom; // start;

  const maxValue = dateTo ? dateTo : dayjs(new Date()).format('YYYYMMDDHHmmss'); //new Date(); //.getFullYear();

  if (dateFrom && stateRootStation.interval !== '1') {
    const _date = dayjs(dateFrom, 'YYYYMMDDHHmmss').toDate();
    _date.setDate(1);
    _date.setMonth(_date.getMonth() + 1);
    minValue = dayjs(_date).format('YYYYMM') + '01000000';
  }

  const handleInterval = (value: string) => {
    dispatch(setIntervalValue(value));
  };

  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
      <Grid item md={12} sx={{ mb: 3 }}>
        <Typography variant='h3'>Filters</Typography>
      </Grid>
      <Grid
        container
        sx={{ display: 'flex', flexDirection: 'column' }}
        rowSpacing={2}
      >
        <Grid item>
          <ToolTipLabel label='Station' />

          <Box
            style={{
              minHeight: 46,
              border: '1px solid #ddd',
              borderRadius: 5,
              padding: 3,
            }}
          >
            {selectedStations.length === 0 && (
              <Typography variant='subtitle2' sx={{ mt: 1, ml: 1 }}>
                Please select on the map
              </Typography>
            )}
            {selectedStations
              ? selectedStations.map((station, index) => {
                  return (
                    <ListItem key={station.station_id}>
                      <Chip
                        size='small'
                        label={station.name}
                        onDelete={() => {
                          dispatch(removeDownloadStation(station));
                        }}
                      />
                    </ListItem>
                  );
                })
              : null}
          </Box>
        </Grid>
        <Grid item>
          <ToolTipLabel label='Interval' />
          <Select
            fullWidth
            size='small'
            value={stateRootStation.interval}
            onChange={(e: SelectChangeEvent<string>) => {
              handleInterval(e.target.value);
            }}
          >
            <MenuItem value={1}>1-minute (Raw)</MenuItem>
            <MenuItem value={10}>10-minute (Processed)</MenuItem>
            <MenuItem value={15}>15-minute (Processed)</MenuItem>
            <MenuItem value={30}>30-minute (Processed)</MenuItem>
            <MenuItem value={60}>1-hour (Processed)</MenuItem>
          </Select>
        </Grid>
        <Grid item>
          <DataFields />
        </Grid>
        <Grid item>
          <ToolTipLabel label='Date and Time (Station local time)' />
          <DatePicker
            value={[
              stateRootStation.startDate || '',
              stateRootStation.endDate || '',
            ]}
            maxValue={maxValue}
            minValue={minValue}
          />
        </Grid>
        <Grid item sx={{ mt: 3 }}>
          <ConfirmDownload />
        </Grid>
        <Grid item sx={{ mt: 3 }}>
          <Box
            style={{
              minHeight: 46,
              border: '1px solid #ddd',
              borderRadius: 5,
              padding: 3,
              display: 'flex',
            }}
          >
            <InfoIcon sx={{ m: 1 }} color={'disabled'} />
            <Typography variant='caption' sx={{ mb: 1, mr: 1, mt: 1 }}>
              One minute interval data is raw, coming directly from the Bureau
              of Meteorology and is not quality checked. 10, 15, 30, and 60
              minute data is aggregated from the same one minute data. The type
              of aggregation depends on the datatype but does not clean or check
              the data in any way. Users should employ their discretion when
              using this data for any purpose.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FiltersForm;
