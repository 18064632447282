import { Button, Grid, Typography } from '@mui/material';

import Cloud from '../../images/icons/Cloud.svg';
import Humidity from '../../images/icons/Humidity.svg';
import Wind from '../../images/icons/Wind.svg';
import Rainfall from '../../images/icons/Rainfall.svg';
import Temperature from '../../images/icons/Temperature.svg';

interface PopupComponentProps {
  showIcon: any;
  popupContent: any;
  handleAdd: () => void;
}

const PopupComponent: React.FunctionComponent<PopupComponentProps> = (
  props
) => {
  const { showIcon, handleAdd, popupContent } = props;

  return (
    <Grid sx={{ height: 240, m: 1 }}>
      <Grid sx={{ mb: 2 }}>
        <Typography variant='subtitle1'>{popupContent.name}</Typography>
        <Grid
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
          item
          xs={12}
        >
          {showIcon['Cloud Level'] || showIcon['Okta'] ? (
            <img alt='Cloud' src={Cloud} height={35} />
          ) : (
            ''
          )}
          {showIcon['Humidity'] && (
            <img alt='Humidity' src={Humidity} height={25} />
          )}
          {showIcon['Rainfall'] && (
            <img alt='Rainfall' src={Rainfall} height={35} />
          )}
          {showIcon['Wind Speed'] || showIcon['Wind Direction'] ? (
            <img alt='Wind' src={Wind} height={35} />
          ) : (
            ''
          )}
          {showIcon['Temperature'] && (
            <img alt='Temperature' src={Temperature} height={35} />
          )}
        </Grid>
      </Grid>
      <Grid sx={{ display: 'flex', mb: 2 }}>
        <Grid item md={6}>
          {' '}
          <Typography variant='subtitle2'>{'Station ID'}</Typography>
          <Typography variant='body2'>{popupContent.station_id}</Typography>
          <Typography variant='subtitle2'>{'Data available from'}</Typography>
          <Typography variant='body2'>{popupContent.start}</Typography>
        </Grid>
        <Grid item md={6}>
          {' '}
          <Typography variant='subtitle2'>{'Coordinates'}</Typography>
          <Typography variant='body2'>
            {popupContent.coordinates[0] + '  ' + popupContent.coordinates[1]}
          </Typography>
          <Typography variant='subtitle2'>{'Data available to'}</Typography>
          <Typography variant='body2'>{popupContent.end}</Typography>
        </Grid>
      </Grid>
      <Grid sx={{ display: 'flex', alignItems: 'center' }}>
        <Grid sx={{ flexGrow: 1 }}>{/* <Link>Link to BOM</Link> */}</Grid>
        <Button variant='contained' onClick={handleAdd}>
          Select
        </Button>
      </Grid>
    </Grid>
  );
};

export default PopupComponent;
