//@ts-nocheck
import { Layer, Source } from 'react-map-gl';
import { FeatureCollection } from 'geojson';
import { CirclePaint } from 'mapbox-gl';
import { selectStations } from '../../../redux/features/stationSlice';
import { useAppSelector } from '../../../redux/hooks';

const stationsLayer: CirclePaint = {
  'circle-color': '#FA777C',
  'circle-radius': ['case', ['==', ['get', 'selected'], false], 8, 12],
  'circle-stroke-width': ['case', ['==', ['get', 'selected'], false], 0, 8],
  'circle-stroke-color': [
    'case',
    ['==', ['get', 'selected'], false],
    '#ffffff',
    '#E61E28',
  ],
  'circle-stroke-opacity': ['case', ['==', ['get', 'selected'], false], 1, 0.3],
};

const Stations = () => {
  const stations = useAppSelector(selectStations);

  return (
    <Source type='geojson' id='stations' data={stations as FeatureCollection}>
      <Layer id='stations' type='circle' paint={stationsLayer}></Layer>
    </Source>
  );
};

export default Stations;
