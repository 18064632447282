import { useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import { apiScope } from '../const'

function useApiAuth() {
  const { instance, accounts } = useMsal();
  const authToken = useCallback(async () => {
    const res = await instance.acquireTokenSilent({
      scopes: [apiScope],
      account: accounts[0],
    });
    const bearer = "Bearer " + res.accessToken;
    return bearer;
  }, [instance, accounts]);

  return authToken;
}

export default useApiAuth