import { FormLabel, Typography } from '@mui/material';
import React from 'react';

interface TooltipLabelProps {
  label: string;
}

const TooltipLabel: React.FunctionComponent<TooltipLabelProps> = ({
  label,
}) => {
  return (
    <FormLabel>
      <Typography variant='body2'>{label}</Typography>
    </FormLabel>
  );
};

export default TooltipLabel;
