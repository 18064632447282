import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feature, FeatureCollection } from 'geojson';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import {jsonToGeojson, StationItem } from '../../utils/map';
import { LabeValue } from '../../components/Filters/DataFieldsCategory';
import { AppDispatch, RootState } from '../store';
import DataFieldsConfig from '../../utils/constants';
import {
  downloadCSV,
  downloadViaEmail,
  IPostStationData,
} from '../../utils/downloadCSV';
import { getDataFields } from '../../utils/dataFieldsHelper';

dayjs.extend(utc);
dayjs.extend(tz);

export interface IUpdateDataFields {
  title: string;
  list: LabeValue[];
}

export interface IUpdateDate {
  name: string | null;
  value: string | null;
}

export interface IDownloadStation {
  station_id: string;
  name: string;
  sta: string;
  startDate: string | number | null;
  endDate: string | number | null;
}

interface IState {
  stationList:StationItem[]
  stations: FeatureCollection ;
  currentStation: Feature | null;
  index: number;
  downloadStations: IDownloadStation[];
  interval: string;
  startDate: string | null;
  endDate: string | null;
  dataFields: { [key: string]: LabeValue[] };
  loading: boolean;
  message: string;
}

const initialState: IState = {
  stationList:[],
  stations: {type: 'FeatureCollection', features:[]},
  currentStation: null,
  index: -1,
  downloadStations: [],
  interval: '1',
  startDate: null,
  endDate: null,
  dataFields: {},
  loading: false,
  message: '',
};

const stationSlice = createSlice({
  name: 'station',
  initialState,
  reducers: {
    selectedStation: (state, action: PayloadAction<Feature>) => {
      try{

        const feature = action.payload;

        if (state.stations && state.index >= 0) {
          //@ts-ignore
          state.stations.features[state.index].properties.selected = false;
        }
        if (feature) {
          const currentIndex = state.stations.features.findIndex(
            (p) => parseInt(p.id + '') === parseInt(feature.id + '')
            );
            state.index = currentIndex;
            //@ts-ignore
            state.stations.features[currentIndex].properties.selected = true;
          }
        }catch(ex){
          console.log('error',ex);
        }
    },
    clearCurrentStation: (state) => {
      if (state.index >= 0) {
        //@ts-ignore
        state.stations.features[state.index].properties.selected = false;
      }
    },
    addDownloadStation: (state, action: PayloadAction<IDownloadStation>) => {
      if (!state.downloadStations) {
        state.downloadStations = [];
      }
      state.startDate = null;
      state.endDate = null;
      state.dataFields = {};
      state.downloadStations = [action.payload];
    },
    removeDownloadStation: (state, action: PayloadAction<IDownloadStation>) => {
      state.downloadStations = state.downloadStations.filter(
        (i) => i.station_id !== action.payload.station_id
      );
      state.dataFields = {};
      state.startDate = null;
      state.endDate = null;
    },
    setIntervalValue: (state, action: PayloadAction<string>) => {
      state.interval = action.payload;
    },
    updateDateValue: (state, action: PayloadAction<IUpdateDate>) => {
      const { name, value } = action.payload;
      if (name === 'start') {
        state.startDate = value;
      } else if (name === 'end') {
        state.endDate = value;
      }
    },
    updateDataFields: (state, action: PayloadAction<IUpdateDataFields>) => {
      const { title, list } = action.payload;
      state.dataFields[title] = list;
    },
    selectDatasets: (state, action: PayloadAction<any>) => {
      const { type } = action.payload;
      // const dataFieldInfo = getDataFields(station_id,state.stationList);

      // const checkDisabled = (fieldName: string): boolean => {
      //   if (!dataFieldInfo) {
      //     return false;
      //   }
      //   return true
      //   //@ts-ignore
      //   // const val = dataFieldInfo[fieldName];
      //   // const res = (val || '').toUpperCase() !== 'FALSE' ? false : true;
      //   // return res;
      // };
      const tmpEmpty: { [key: string]: LabeValue[] } = {};
      const dataCentreValues = [
        'tempDry',
        'tempWet',
        'speedAve',
        'speedMax',
        'speedMin',
        'dirAve',
        'dirMax',
        'dirMin',
      ];
      const acousticValues = ['speedAve', 'rainMin'];
      const windValues = [
        'speedAve',
        'speedMax',
        'speedMin',
        'speedStd',
        'speedNo',
        'dirMax',
        'dirAve',
        'dirMin',
        'dirStd',
        'dirNo',
      ];

      const result: { [key: string]: LabeValue[] } = {};

      Object.keys(DataFieldsConfig).forEach((key: string) => {
        tmpEmpty[key] = [];
        const list: LabeValue[] = DataFieldsConfig[key];
        let tmpResult;
        if (type === 'all') {
          tmpResult = list;
        } else if (type === 'acoustic') {
          tmpResult = list.filter(
            (p) => acousticValues.includes(p.value)
          );
        } else if (type === 'wind') {
          tmpResult = list.filter(
            (p) => windValues.includes(p.value)
          );
        } else if (type === 'dataCentre') {
          tmpResult = list.filter(
            (p) => dataCentreValues.includes(p.value)
          );
        }
        if (tmpResult && tmpResult.length > 0) {
          result[key] = tmpResult;
        }
      });
      if (JSON.stringify(state.dataFields) === JSON.stringify(result)) {
        state.dataFields = tmpEmpty;
      } else {
        state.dataFields = result;
      }
    },
    updateMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    saveStations:(state, action: PayloadAction<any>)=>{
      state.stationList = action.payload as StationItem[]
      state.stations = jsonToGeojson(action.payload as StationItem[])

    }
  },
});

export const downloadAsync =
  (userEmail: string,downloadType:number, token:string) => async (dispatch: AppDispatch, getState: any) => {
    try {
      const state: RootState = getState();
      const { station } = state;
      const { interval, startDate, endDate, downloadStations, dataFields,stationList } =
        station;
      const dataFieldsValues = Object.values(dataFields);
      let columns: string[] = [];
      if (dataFieldsValues && dataFieldsValues.length > 0) {
        columns = dataFieldsValues
          .reduce((a, b) => [...a, ...b])
          .map((p) => p.value);
      }
      const station_id = String(downloadStations[0].station_id);
      const { TimeZone } = getDataFields(station_id,stationList) || {};

      const start = startDate
        ? dayjs.utc(dayjs.tz(startDate, TimeZone)).format('YYYYMMDDHHmmss')
        : startDate;
      const end = endDate
        ? dayjs.utc(dayjs.tz(endDate, TimeZone)).format('YYYYMMDDHHmmss')
        : endDate;

      const requestedTime = dayjs().format('YYYYMMDDHHmmss');
      const requestUtc = dayjs().utc().format('YYYYMMDDHHmmss');

      const data: IPostStationData = {
        requestedTime,
        requestUtc,
        interval,
        startDate: start || '',
        endDate: end || '',
        userEmail,
        stations: [String(downloadStations[0].station_id)],
        stationName: [downloadStations[0].name],
        columns: ['UTC', 'localTime', ...columns],
      };
      const startTime = dayjs(start, 'YYYYMMDDHHmmss').toDate().getTime();
      const endTime = dayjs(end, 'YYYYMMDDHHmmss').toDate().getTime();
      const halfYear = 1000 * 60 * 60 * 24 * 30 * 6;

      if (endTime - startTime > halfYear) {
        // eslint-disable-next-line
        const msg: string = await downloadViaEmail(data,downloadType, token);
        dispatch(
          updateMessage(
              'Task has been sent. It will be send to your email address when we finish the processing'
          )
        );
      } else {
        await downloadCSV(data, token);
      }
    } catch (error) {
      throw new Error('get records error');
    }
    return;
  };

//selector
export const selectStation = (state: RootState) => state.station;
export const selectStationList = (state: RootState) => state.station.stationList;
export const selectStations = createSelector(
  selectStation,
  (station) => station.stations
);

export const selectCurrentStation = createSelector(
  selectStation,
  (state: IState): Feature => {
    return state.stations.features[state.index];
  }
);

export const selectDownloadStations = createSelector(
  selectStation,
  (state: IState) => {
    return state.downloadStations;
  }
);

export const stationStateRoot = createSelector(
  selectStation,
  (state: IState): IState => {
    return state;
  }
);

//export
export const {
  selectedStation,
  clearCurrentStation,
  addDownloadStation,
  removeDownloadStation,
  updateDateValue,
  setIntervalValue,
  updateDataFields,
  selectDatasets,
  updateMessage,
  saveStations,
} = stationSlice.actions;
export default stationSlice.reducer;
