import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../auth/authConfig';
import { Box, Button, Card, Grid, Typography } from '@mui/material';
interface LoginProps {}

const backgroundContainer = {
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const Login: React.FunctionComponent<LoginProps> = () => {
  const { instance } = useMsal();

  const handleLogin = async () => {
    await instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  };

  return (
    <Box
      sx={{
        ...backgroundContainer,
        backgroundColor: 'secondary.dark',
      }}
    >
      <Card
        sx={{
          display: 'flex',
          width: 500,
          flexDirection: 'column',
          backgroundColor: 'primary.main',
          p: 5,
        }}
      >
        <Grid>
          <Typography
            variant='h4'
            sx={{ fontWeight: 400, color: 'secondary.light' }}
          >
            ARUP -
          </Typography>
        </Grid>
        <Typography
          variant='h3'
          sx={{ fontWeight: 700, color: 'secondary.light' }}
        >
          WEATHER OBSERVATION DATA PLATFORM
        </Typography>
        <Button
          variant='contained'
          onClick={handleLogin}
          sx={{
            backgroundColor: 'secondary.light',
            color: 'primary.main',
            mt: 3,
            '&:hover': {
              backgroundColor: '#EFEFEF',
            },
          }}
        >
          Login
        </Button>
      </Card>
    </Box>
  );
};

export default Login;
