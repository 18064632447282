import dayjs from 'dayjs';

export const isOverHalfYear = (startDate, endDate) => {
  const startTime = dayjs(startDate, 'YYYY/MM/DD HHmmss').toDate().getTime();
  const endTime = dayjs(endDate, 'YYYY/MM/DD HHmmss').toDate().getTime();
  const halfYear = 1000 * 60 * 60 * 24 * 30 * 6;
  if (endTime - startTime > halfYear) {
    return true;
  }
};
