import { LabeValue } from "../components/Filters/DataFieldsCategory"

interface IDataFieldConfig
  { [key: string]: LabeValue[] }


const DataFieldsConfig:IDataFieldConfig = {
  Temperature:[
    { label: 'Dry Temperature(Celsius)', value: 'tempDry' },
    { label: 'Wet Temperature(Celsius)', value: 'tempWet' },
    {
      label: 'Dew Point Temperature(Celsius)',
      value: 'tempDew',
    },
    {
      label: 'Terrain Temperature(Celsius)',
      value: 'tempTerrain',
    },
    { label: 'Water Temperature(Celsius)', value: 'tempWater' },
  ],

  Humidity:[
    { label: 'Relative Humidity(%)', value: 'relHumidity' },
  ],
 'Wind Speed':[
  { label: 'Average Speed(m/s)', value: 'speedAve' },
  { label: 'Maximum Speed(m/s)', value: 'speedMax' },
  { label: 'Minimum Speed(m/s)', value: 'speedMin' },
  {
    label: 'Speed Standard Deviation(m/s)',
    value: 'speedStd',
  },
  { label: 'Speed Number(m/s)', value: 'speedNo' },
],
'Wind Direction': [
  { label: 'Average Direction(degree)', value: 'dirAve' },
  { label: 'Maximum Direction(degree)', value: 'dirMax' },
  { label: 'Minimum Direction(degree)', value: 'dirMin' },
  {
    label: 'Direction Standard Deviation(degree)',
    value: 'dirStd',
  },
  { label: 'Direction Number(degree)', value: 'dirNo' },
],
Rainfall: [
  { label: 'Rain Accumulation(mm)', value: 'rainCum' },
  { label: 'Rain In Selected Interval(mm)', value: 'rainMin' },
],
Pressure: [{ label: 'Pressure(kPa)', value: 'pressure' }],
'Cloud Level':[
  { label: 'Cloud Level 1(m)', value: 'cloud1' },
  { label: 'Cloud Level 2(m)', value: 'cloud2' },
  { label: 'Cloud Level 3(m)', value: 'cloud3' },
  { label: 'Cloud Level 4(m)', value: 'cloud4' },
  { label: 'Cloud Level 5(m)', value: 'cloud5' },
],
Okta: [
  { label: 'Cloud Amount 1', value: 'OKTA1' },
  { label: 'Cloud Amount 2', value: 'OKTA2' },
  { label: 'Cloud Amount 3', value: 'OKTA3' },
],
Visibility: [{ label: 'Visibility(m)', value: 'visibility' }],
Metar:[
  { label: 'METAR 1', value: 'metar1' },
  { label: 'METAR 2', value: 'metar2' },
  { label: 'METAR 3', value: 'metar3' },
  { label: 'METAR 4', value: 'metar4' },
],
Synop:[
  { label: 'SYNOP 1', value: 'synop1' },
  { label: 'SYNOP 2', value: 'synop2' },
],
Error:[{ label: 'Error', value: 'error' }],
'Water Level':[
  { label: 'Water Level 1(m)', value: 'waterLevel1' },
  { label: 'Water Level 2(m)', value: 'waterLevel2' },
  { label: 'Water Level 3(m)', value: 'waterLevel3' },
],
'Thunder Count':[
  { label: 'Thunder Count', value: 'thunderCount' },

]
}
export default DataFieldsConfig