import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface MessageDialogProps {
  message: string;
  onClose: () => void;
}

const MessageDialog: React.FunctionComponent<MessageDialogProps> = (props) => {
  const handleClose = () => {
    props.onClose && props.onClose();
  };
  return (
    <div>
      <Dialog
        open={!!props.message}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Thanks for using!</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MessageDialog;
