import React from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material';

import Login from './Login';

const Root = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
}));

const AppLogin: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  console.log('isAuthenticated', isAuthenticated);

  return <Root>{isAuthenticated ? <Outlet /> : <Login />}</Root>;
};

export default AppLogin;
