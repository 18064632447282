import { createTheme } from '@mui/material/styles';

const SansSeriffonts = "'Arial', sans-serif";
const theme = createTheme({
  palette: {
    // type: "light",
    primary: {
      main: '#E61E28', //arup red
      light: '#FA777C', //lighter arup red
    },
    secondary: {
      main: '#FFFFFF', //bg color
      light: '#FAFAFA',
      dark: '#EFEFEF', //darker bg color
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: 50,
          textTransform: 'capitalize',
        },
      },
    },
  },
  typography: {
    fontFamily: SansSeriffonts,
    h3: {
      fontSize: 32,
      fontFamily: SansSeriffonts,
      letterSpacing: 0.3,
      lineHeight: '52px',
      color: '#1C1C1C',
    },
    h4: {
      fontSize: 26,
      fontFamily: 'Times New Roman',
      fontWeight: 600,
      letterSpacing: 0.3,
      lineHeight: '52px',
      color: '#E61E28',
    },
    subtitle1: {
      fontSize: 20,
      fontFamily: SansSeriffonts,
      letterSpacing: 0.5,
      lineHeight: '32px',
      color: '#1C1C1C',
    },
    subtitle2: {
      fontSize: 16,
      fontFamily: SansSeriffonts,
      letterSpacing: 0.25,
      lineHeight: '24px',
      color: '#767676',
    },
    body1: {
      fontSize: 16,
      fontFamily: SansSeriffonts,
      letterSpacing: 0.5,
      lineHeight: '32px',
      color: '#1C1C1C',
    },

    body2: {
      fontSize: 14,
      fontFamily: SansSeriffonts,
      letterSpacing: 0.4,
      lineHeight: '24px',
      color: '#1C1C1C',
    },
    caption:{
      fontSize: 14,
      fontFamily: SansSeriffonts,
      letterSpacing: 0.4,
      lineHeight: '20px',
      color: '#1C1C1C',
    },
    overline:{
      fontSize: 14,
      fontFamily: 'Times New Roman',
      letterSpacing: 0.4,
      lineHeight: '20px',
      color: '#E61E28',
      fontWeight: 600,
      textTransform: 'none'
    }
  },
});

export default theme;
