import {loginRequest, graphConfig} from './authConfig';

export const authToken = async (instance: any, accounts: any) => {
  const token = await instance.acquireTokenSilent({
    ...loginRequest,
    account: accounts[0],
  });
  const bearer = 'Bearer ' + token.accessToken;
  return bearer;
};

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param bearer
 */
export async function callMsGraph(bearer: string) {
  const headers = new Headers();
  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
