import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";


type UserState = {
  loginDetail: any | null
  user:{}
}

const initialState: UserState = {
  loginDetail:null,
  user: {}
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoginDetail: (state, action) => {
      state.loginDetail = action.payload
    },
    getUser: (state, action) => {
      state.user = action.payload
    },
  }
})

//selector
export const selectUser = (state:RootState) => state.user


//export
export const { getUser, setLoginDetail } = userSlice.actions
export default userSlice.reducer