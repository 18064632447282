import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

type SettingState = {
  loading: boolean;
  message:string
};

const initialState: SettingState = {
  loading: false,
  message:'',
};

const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setNotification: (state,action:PayloadAction<string>)=>{
      state.message = action.payload;
    }
  },
});

//selector
export const selectSetting = (state: RootState) => state.setting;
export const selectLoading = createSelector(
  selectSetting,
  (setting) => setting.loading
);

//export
export const { setLoading ,setNotification} = settingSlice.actions;

export default settingSlice.reducer;
