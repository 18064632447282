import { StationItem } from './map';

export interface IStationDataField {
  Site: string| number ; //
  Dist: number | string | null;
  'Site name': string;
  Start: number | null;
  End: string;
  Lat: number | null;
  Lon: number | null;
  Source: string;
  STA: string;
  'Height (m)': number | null;
  Bar_ht: number | string | null;
  WMO: number | null;
  TimeZone: string;
  dateFrom: number | null;
  dateTo: number | null;
  UTC: string;
  localTime: string;
  tempDry: string;
  tempWet: string;
  tempDew: string;
  tempTerrain: string;
  tempWater: string;
  relHumidity: string;
  rainCum: string;
  rainMin: string;
  speedAve: string;
  speedMax: string;
  speedMin: string;
  speedStd: string;
  speedNo: string;
  dirAve: string;
  dirMax: string;
  dirMin: string;
  dirStd: string;
  dirNo: string;
  pressure: string;
  cloud1: string;
  cloud2: string;
  cloud3: string;
  cloud4: string;
  cloud5: string;
  OKTA1: string;
  OKTA2: string;
  OKTA3: string;
  visibility: string;
  metar1: string;
  metar2: string;
  metar3: string;
  metar4: string;
  synop1: string;
  synop2: string;
  error: string;
  waterLevel1: string;
  waterLevel2: string;
  waterLevel3: string;
  thunderCount: string;
}

// export const getDataFields1 = (
//   station_id: string|number
// ): IStationDataField | undefined => {
//   const list: IStationDataField[] = StationDataFields;
//   const result = list.find((i) => i.Site === station_id);
//   return result;
// };


export const getDataFields = (
  station_id: string|number,
  list:StationItem[]
): IStationDataField | undefined => {

  const result:StationItem|undefined = list.find((i) => i.station === station_id) ;
  if(result){
     const {
      dataFields,
      dateFrom,
      dateTo,
      lat,
      lon,
      station,
      stationName,
      timeZone,
    } = result;

      const obj:IStationDataField = {
        Site:station,
        Dist:0,
        'Site name': stationName,
        Start:0,
        End:'',
        Lat:lat,
        Lon:lon,
        Source:'',
        STA:'',
        'Height (m)':0,
        Bar_ht:'',
        WMO:0,
        TimeZone:timeZone,
        dateFrom:parseInt( dateFrom),
        dateTo:parseInt( dateTo),
        UTC:'',
        localTime:'',
        tempDry: dataFields.Temperature.tempDry ? 'TRUE' : 'FALSE',
        tempWet:dataFields.Temperature.tempWet ? 'TRUE' : 'FALSE',
        tempDew:dataFields.Temperature.tempDew ? 'TRUE' : 'FALSE',
        tempTerrain:dataFields.Temperature.tempTerrain ? 'TRUE' : 'FALSE',
        tempWater:dataFields.Temperature.tempWater ? 'TRUE' : 'FALSE',
        relHumidity:dataFields.Humidity.relHumidity ? 'TRUE' : 'FALSE',
        rainCum:dataFields.Rain.rainCum? 'TRUE' : 'FALSE',
        rainMin:dataFields.Rain.rainMin? 'TRUE' : 'FALSE',
        speedAve:dataFields['Wind Speed'].speedAve? 'TRUE' : 'FALSE',
        speedMax:dataFields['Wind Speed'].speedMax? 'TRUE' : 'FALSE',
        speedMin:dataFields['Wind Speed'].speedMin? 'TRUE' : 'FALSE',
        speedStd:dataFields['Wind Speed'].speedStd? 'TRUE' : 'FALSE',
        speedNo:dataFields['Wind Speed'].speedNo? 'TRUE' : 'FALSE',
        dirAve:dataFields['Wind Direction'].dirAve? 'TRUE' : 'FALSE',
        dirMax:dataFields['Wind Direction'].dirMax? 'TRUE' : 'FALSE',
        dirMin:dataFields['Wind Direction'].dirMin? 'TRUE' : 'FALSE',
        dirStd:dataFields['Wind Direction'].dirStd? 'TRUE' : 'FALSE',
        dirNo:dataFields['Wind Direction'].dirNo? 'TRUE' : 'FALSE',
        pressure:dataFields.Pressure.pressure? 'TRUE' : 'FALSE',
        cloud1:dataFields['Cloud Level'].cloud1? 'TRUE' : 'FALSE',
        cloud2:dataFields['Cloud Level'].cloud2? 'TRUE' : 'FALSE',
        cloud3:dataFields['Cloud Level'].cloud3? 'TRUE' : 'FALSE',
        cloud4:dataFields['Cloud Level'].cloud4? 'TRUE' : 'FALSE',
        cloud5:dataFields['Cloud Level'].cloud5? 'TRUE' : 'FALSE',
        OKTA1:dataFields.Okta.OKTA1? 'TRUE' : 'FALSE',
        OKTA2:dataFields.Okta.OKTA2? 'TRUE' : 'FALSE',
        OKTA3:dataFields.Okta.OKTA3? 'TRUE' : 'FALSE',
        visibility:dataFields.Visibility.visibility? 'TRUE' : 'FALSE',
        metar1:dataFields.Metar.metar1? 'TRUE' : 'FALSE',
        metar2:dataFields.Metar.metar2? 'TRUE' : 'FALSE',
        metar3:dataFields.Metar.metar3? 'TRUE' : 'FALSE',
        metar4:dataFields.Metar.metar4? 'TRUE' : 'FALSE',
        synop1:dataFields.Synop.synop1? 'TRUE' : 'FALSE',
        synop2:dataFields.Synop.synop2? 'TRUE' : 'FALSE',
        error:dataFields.Error.error? 'TRUE' : 'FALSE',
        waterLevel1:dataFields['Water Level'].waterLevel1? 'TRUE' : 'FALSE',
        waterLevel2:dataFields['Water Level'].waterLevel2? 'TRUE' : 'FALSE',
        waterLevel3:dataFields['Water Level'].waterLevel3? 'TRUE' : 'FALSE',
        thunderCount:dataFields['Thunder Count'].thunderCount? 'TRUE' : 'FALSE',
      }
      return obj;
  }
  return undefined;
};
