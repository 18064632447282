import ReactDOM from "react-dom/client";
import "@arc-web/components/dist/themes/index.css";
import "@arc-web/components/dist/themes/light.css";
import "@arc-web/components/dist/themes/dark.css";
import { setBasePath } from "@arc-web/components/dist/utilities/base-path.js";
import "./index.css";
import "mapbox-gl/dist/mapbox-gl.css";

import { Provider } from "react-redux";
import { MsalProvider } from "@azure/msal-react";
import {
  PublicClientApplication,
  AuthenticationResult,
  EventType,
  EventMessage,
} from "@azure/msal-browser";

import store from "./redux/store";
import { msalConfig } from "./auth/authConfig";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
setBasePath("/");

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }
  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  root.render(
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <App />
      </Provider>
    </MsalProvider>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
