import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import Cloud from '../../images/icons/Cloud.svg';
import Humidity from '../../images/icons/Humidity.svg';
import Wind from '../../images/icons/Wind.svg';
import Rainfall from '../../images/icons/Rainfall.svg';
import Temperature from '../../images/icons/Temperature.svg';

interface LegendProps {}

const Legend: React.FC<LegendProps> = () => {
  return (
    <Grid>
      <Paper
        variant='outlined'
        style={{
          width: 160,
          height: 180,
          padding: 4,
          margin: 4,
          backgroundColor: 'secondary.main',
        }}
      >
        <Grid container>
          <Grid item sx={{ display: 'flex' }} md={12}>
            <img alt='Temperature' src={Temperature} height={35} />
            <Typography>Temperature</Typography>
          </Grid>

          <Grid item sx={{ display: 'flex' }} md={12}>
            <img alt='Cloud' src={Cloud} height={35} />
            <Typography>Cloud</Typography>
          </Grid>
          <Grid item sx={{ display: 'flex' }} md={12}>
            <img alt='Rainfall' src={Rainfall} height={35} />
            <Typography>Rainfall</Typography>
          </Grid>
          <Grid item sx={{ display: 'flex' }} md={12}>
            <img alt='Wind' src={Wind} height={35} />
            <Typography>Wind</Typography>
          </Grid>
          <Grid item sx={{ display: 'flex' }} md={12}>
            <img
              alt='Humidity'
              src={Humidity}
              height={25}
              style={{ padding: 2, margin: 4 }}
            />
            <Typography>Humidity</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Legend;
