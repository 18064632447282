import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import dayjs from 'dayjs';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setLoading, setNotification } from '../../redux/features/settingSlice';
import {
  downloadAsync,
  stationStateRoot,
  updateMessage,
} from '../../redux/features/stationSlice';
import useApiAuth from '../../auth/useApiAuth'
import { isOverHalfYear } from '../../utils/checkHalfYear';
import Loading from '../Loading';
import MessageDialog from '../MessageDialog';
import { LabeValue } from './DataFieldsCategory';

const ConfirmDownload = () => {
  const [open, setOpen] = useState(false);
  const [downloadType, setDownloadType] = useState(0);
  const [info, setInfo] = useState(
    'Check the data that will be downloaded as a CSV'
  );
  const authToken = useApiAuth()
  const dispatch = useAppDispatch();

  const {
    message,
    interval,
    startDate,
    endDate,
    downloadStations,
    dataFields,
  } = useAppSelector(stationStateRoot);

  const handleClickOpen = (type: number) => {
    if (!downloadStations || !downloadStations.length) {
      dispatch(setNotification('Station required!'));
      return;
    }
    const exists =
      Object.values(dataFields).filter((p) => p.length > 0).length > 0;
    if (!exists) {
      dispatch(setNotification('Data fields required!'));
      return;
    }
    if (!startDate) {
      dispatch(setNotification('Start date required!'));
      return;
    }
    if (!endDate) {
      dispatch(setNotification('End date required!'));
      return;
    }

    setOpen(true);
    setDownloadType(type);
  };

  const handleClose = () => {
    setOpen(false);
    setDownloadType(0);
  };

  const { accounts } = useMsal();
  const userEmail = accounts.length > 0 ? accounts[0].username : '';

  const handleDownload = async () => {
    dispatch(setLoading(true));
    const token = await authToken()
    await dispatch(downloadAsync(userEmail, downloadType, token));
    dispatch(setLoading(false));
    handleClose();
  };

  useEffect(() => {
    const startTime = dayjs(startDate, 'YYYY/MM/DD HHmmss').toDate().getTime();
    const endTime = dayjs(endDate, 'YYYY/MM/DD HHmmss').toDate().getTime();
    const halfYear = 1000 * 60 * 60 * 24 * 30 * 6;
    if (endTime - startTime > halfYear) {
      setInfo('An email will be sent to you when download is ready');
    }
  }, [startDate, endDate]);

  const isLong = isOverHalfYear(startDate, endDate);

  return (
    <>
      {isLong ? (
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            fullWidth
            variant='contained'
            onClick={() => handleClickOpen(2)}
          >
            Download as CSV
          </Button>
          <Button
            fullWidth
            variant='contained'
            onClick={() => handleClickOpen(1)}
          >
            Download as Parquet
          </Button>
        </Grid>
      ) : (
        <Button
          fullWidth
          variant='contained'
          onClick={() => handleClickOpen(0)}
        >
          Download
        </Button>
      )}

      <MessageDialog
        message={message}
        onClose={() => dispatch(updateMessage(''))}
      />
      <Dialog
        maxWidth={false}
        fullWidth={true}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography variant='h3'>Confirm download</Typography>
          <Typography variant='subtitle2' sx={{ mb: 2 }}>
            {info}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component='form'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              height: 'fit-content',
            }}
          >
            <Loading />
            <Grid>
              <Grid>
                <Typography variant='subtitle1'>Station IDs </Typography>
                {downloadStations.map((station) => {
                  return (
                    <Typography variant='body2' key={station.station_id}>
                      {station.station_id}
                    </Typography>
                  );
                })}
              </Grid>
              <Grid>
                <Typography variant='subtitle1'>Station Name </Typography>
                {downloadStations.map((station) => {
                  return (
                    <Typography variant='body2' key={station.station_id}>
                      {station.name}
                    </Typography>
                  );
                })}
              </Grid>
              <Grid>
                <Typography variant='subtitle1'>Intervals</Typography>
                <Typography variant='body2'>
                  {interval === '1'
                    ? `${interval} - minute(Raw)`
                    : `${interval} - minute(Processed)`}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant='subtitle1'>Date and Time</Typography>
                <Typography variant='body2'>
                  {startDate} - {endDate}
                </Typography>
              </Grid>
              <Typography variant='subtitle1'>Data fields</Typography>
              <Grid container>
                {Object.keys(dataFields).map((key, index) => {
                  const list: LabeValue[] = dataFields[key];
                  return (
                    <Grid item md={2} key={index}>
                      <Typography variant='body1'>{key}</Typography>

                      {list.map((keyValue: LabeValue) => {
                        return (
                          <Typography variant='body2' key={keyValue.value}>
                            {keyValue.label}
                          </Typography>
                        );
                      })}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid sx={{ m: 2 }}>
            <Button variant='contained' onClick={handleClose}>
              Edit selection
            </Button>
            <Button variant='contained' onClick={handleDownload}>
              Download
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmDownload;
