import { FeatureCollection, Feature, Point } from 'geojson';

export type Station = {
  Site: string | number | undefined;
  Dist: string;
  'Site name': string;
  Start: number | null;
  End: number | string | null;
  Lat: number;
  Lon: number;
  Source: string;
  STA: string | null;
  'Height (m)': number | null;
  Bar_ht: number | null;
  WMO: number | null;
  TimeZone: string |null;
  dateFrom: number |string| null;
  dateTo: number |string| null;
  selected?: boolean;
}

export type StationItem = {
  PartitionKey: string,
  RowKey:string,
  dataFields: DataFields,
  dateFrom: string,
  dateTo: string,
  lat: number,
  lon: number,
  station: string
  stationName: string
  timeZone:string
}
export type DataFields= {
  Temperature: Temperature,
  Humidity: Humidity,
  Rain: Rain,
  "Wind Speed":WindSpeed,
  "Wind Direction": WindDirection,
  Pressure: Pressure,
  "Cloud Level": CloudLevel,
  Okta: Okta,
  Visibility: Visibility,
  Metar: Metar,
  Synop: Synop,
  Error: Error,
  "Water Level": WaterLevel,
  "Thunder Count": ThunderCount
}

export type Temperature ={
  tempDry:boolean
  tempWet:boolean
  tempDew:boolean
  tempTerrain:boolean
  tempWater:boolean

}
export type Humidity ={
  relHumidity:boolean
}

export type Rain ={
  rainCum:boolean
  rainMin:boolean
}

export type WindSpeed ={
  speedAve:boolean
  speedMax:boolean
  speedMin:boolean
  speedStd:boolean
  speedNo:boolean
}

export type WindDirection ={
  dirAve:boolean
  dirMax:boolean
  dirMin:boolean
  dirStd:boolean
  dirNo:boolean

}
export type Pressure ={
  pressure: boolean
}
export type CloudLevel ={
  cloud1:boolean
  cloud2:boolean
  cloud3:boolean
  cloud4:boolean
  cloud5:boolean

}

export type Okta ={
  OKTA1:boolean
  OKTA2:boolean
  OKTA3:boolean
}
export type Visibility ={
  visibility: boolean
}

export type Metar ={
  metar1:boolean
  metar2:boolean
  metar3:boolean
  metar4:boolean
}
export type Synop ={
  synop1: boolean
  synop2: boolean
}
export type Error ={
  error: boolean
}

export type WaterLevel ={
  waterLevel1:boolean
  waterLevel2:boolean
  waterLevel3:boolean
}
export type ThunderCount ={
  thunderCount:boolean
}

export type Stations = Station[]


// export const jsonToGeojson = (jsonData: Stations):FeatureCollection => {

//   const features: Feature[] = jsonData.map((s: Station) => {
//     const { Site, Dist, Start, End, Source, STA, Bar_ht, WMO, TimeZone, dateFrom,dateTo } = s;
//     const pointGeometry: Point = {
//       type: 'Point',
//       coordinates: [s.Lon, s.Lat],
//     };
//     return {
//       type: 'Feature',
//        id: `${Site}`,
//       geometry: pointGeometry ,
//       properties: {
//         site: Site,
//         dist: Dist,
//         name: s['Site name'],
//         sta: STA,
//         start: Start,
//         end: End,
//         source: Source,
//         height: s['Height (m)'],
//         bar_ht: Bar_ht,
//         wmo: WMO,
//         dateFrom,
//         dateTo,
//         timezone: TimeZone,
//         selected: false,
//       },
//     };
//   });

//   return {type: 'FeatureCollection', features}

// }

export const jsonToGeojson = (jsonData: StationItem[]):FeatureCollection => {

  const features: Feature[] = jsonData.map((s: StationItem) => {
    const {  timeZone, dateFrom,dateTo, station, stationName} = s;
    const pointGeometry: Point = {
      type: 'Point',
      coordinates: [s.lon, s.lat],
    };
    return {
      type: 'Feature',
       id: `${station}`,
      geometry: pointGeometry ,
      properties: {
        site: station,
        name: stationName,
        dateFrom,
        dateTo,
        timezone: timeZone,
        selected: false,
      },
    };
  });

  return {type: 'FeatureCollection', features}

}