import {useEffect} from "react";
import { Grid, Box, Paper } from "@mui/material";

import Filters from "../Filters";
import Map from "../Map";
import Header from "../Header";
import Loading from '../Loading';
import useApiAuth from '../../auth/useApiAuth'
import { useAppDispatch } from "../../redux/hooks";
import { saveStations } from "../../redux/features/stationSlice";
import { setLoading } from '../../redux/features/settingSlice';
import { urlMetaData } from '../../const'

interface HomeProps {}

const paperContainer = {
  m: 2,
  height: `calc(100% - 28px)`,
};

const Home: React.FunctionComponent<HomeProps> = () => {
  const dispatch = useAppDispatch();
  const authToken = useApiAuth()
  useEffect(() => {
    async function getStations() {      
      try {
        dispatch(setLoading(true));
        const token = await authToken()
        const res = await fetch(urlMetaData,{
          headers: {
            Authorization: token,
            'Content-Type': 'application/json'
          },
        })
        const data = await res.json()
        dispatch(saveStations(data));
        dispatch(setLoading(false));       
      } catch(err) {
        dispatch(setLoading(false));
        console.log('Failed to fetch stations:: ', err)
      }
    }
    getStations()
    // eslint-disable-next-line
  }, []);

  return (
    <Grid>
      <Header />
      <Box style={{ height: "100vh" }}>
        <Grid
          container
          sx={{
            height: `calc(100% - 56px)`,
            backgroundColor: "secondary.dark",
          }}
        >
          <Loading />
          <Grid item md={4}>
            <Paper variant="outlined" sx={{ ...paperContainer }}>
              <Filters />
            </Paper>
          </Grid>
          <Grid item md={8}>
            <Box sx={{ ...paperContainer }}>
              <Map />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default Home;
