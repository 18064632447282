import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import Home from './components/Home';
import AppLogin from './components/Login';
import theme from './theme';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppLogin />}>
      <Route path='/' element={<Home />} />
    </Route>
  )
);

const App = () => {
  
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};

export default App;
