import React, { useRef, useState } from 'react';
import ReactMapGL, { MapRef, MapProvider, Popup } from 'react-map-gl';
import dayjs from 'dayjs';

import 'mapbox-gl/dist/mapbox-gl.css';

import mapboxgl from 'mapbox-gl';

//@ts-ignore
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import Stations from './Layers/Stations';
import { Grid, Tabs, Tab, Box } from '@mui/material';
import {
  addDownloadStation,
  clearCurrentStation,
  selectStationList,
  selectedStation,
} from '../../redux/features/stationSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getDataFields, IStationDataField } from '../../utils/dataFieldsHelper';
import DataFieldsConfig from '../../utils/constants';
import { LabeValue } from '../Filters/DataFieldsCategory';
import Legend from './Legend';
import PopupComponent from './PopupComponent';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const INTERACTIVE_LAYER_ID = 'stations';

interface MapProps {}

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

const Map: React.FunctionComponent<MapProps> = (props) => {
  const [popupContent, setPopupContent] = useState<any | null>(null);
  const [popupLngLat, setPopupLngLat] = useState<any | null>(null);
  const [showIcon, setShowIcon] = useState<{ [key: string]: boolean }>({});
  const stationList = useAppSelector(selectStationList);
  const dispatch = useAppDispatch();
  const mapRef = useRef<MapRef>(null);
  const [featuresTab, setFeaturesTab] = useState<any | null>([]);
  const [tabIndex, setTabIndex] = useState<any | null>(0);
  const geocoder = new MapboxGeocoder({
    accessToken: MAPBOX_TOKEN,
    marker: false,
    countries: 'au',
  });
  const initialState = {
    longitude: 151.2093,
    latitude: -33.91,
    zoom: 10,
  };

  const onMapLoad = () => {
    const map = mapRef.current;
    if (!map) return;
    map.addControl(geocoder);

    navigator.geolocation.getCurrentPosition((pos) => {
      map.flyTo({
        speed: 2,
        center: [pos.coords.longitude, pos.coords.latitude],
      });
    });

    mapRef.current.on('mouseover', INTERACTIVE_LAYER_ID, (e) => {
      map.getCanvas().style.cursor = 'pointer';
    });
  };

  const handleAdd = (popupContent: any) => {
    dispatch(
      addDownloadStation({
        station_id: popupContent.station_id,
        name: popupContent.name,
        sta: popupContent.sta,
        startDate: popupContent.start,
        endDate: popupContent.end,
      })
    );
    setPopupLngLat(null);
    dispatch(clearCurrentStation());
  };

  const handleClick = (e: any) => {
    //@ts-ignore
    const feature = e.features.find((f) => f.layer.id === 'stations');

    setFeaturesTab([]);
    if (!feature) {
      setPopupContent(null);
      setPopupLngLat(null);
      return;
    }
    dispatch(selectedStation(JSON.parse(JSON.stringify(feature))));
    if (e.features.length > 1) {
      const tabs = e.features.map((item: any) => {
        const { name, site, sta, dateFrom, dateTo } = item.properties;
        const data: any = {};
        data.name = name;
        data.station_id = site;
        data.sta = sta;

        //@ts-ignore
        data.coordinates = item.geometry.coordinates.map((i) => +i.toFixed(4));
        if (dateFrom) {
          data.start = dayjs(dateFrom + '', 'YYYYMMDDHHmmss').format(
            'YYYY-MM-DD'
          );
        }
        if (dateTo) {
          data.end = dayjs(dateTo + '', 'YYYYMMDDHHmmss').format('YYYY-MM-DD');
        }
        data.feature = item;
        return data;
      });
      setFeaturesTab(tabs);
      setPopupLngLat(e.lngLat);
      return;
    }

    const name = feature.properties.name;
    const station_id = feature.properties.site;
    const sta = feature.properties.sta;
    const start = feature.properties.dateFrom
      ? dayjs(feature.properties.dateFrom + '', 'YYYYMMDDHHmmss').format(
          'YYYY-MM-DD'
        )
      : '';

    const end = feature.properties.dateTo
      ? dayjs(feature.properties.dateTo + '', 'YYYYMMDDHHmmss').format(
          'YYYY-MM-DD'
        )
      : '';
    //@ts-ignore
    const coordinates = feature.geometry.coordinates.map((i) => +i.toFixed(4));
    if (name) {
      setPopupContent({ name, station_id, coordinates, start, end, sta });
      checkDataFieldsIcon(station_id);

      console.log(coordinates);
    } else {
      setPopupContent(null);
    }

    console.log(e.lngLat);

    setPopupLngLat(e.lngLat);
  };

  const checkDataFieldsValue = (
    dataFieldInfo: IStationDataField,
    fieldName: string
  ): boolean => {
    if (!dataFieldInfo) {
      return false;
    }
    //@ts-ignore
    const val = dataFieldInfo[fieldName];
    const res = val && val.toUpperCase() === 'TRUE' ? true : false;
    return res;
  };

  const checkDataFieldsIcon = (station_id: string) => {
    const dataFields = getDataFields(station_id, stationList);
    if (!dataFields) {
      return;
    }
    const tmpData: { [key: string]: boolean } = {};

    Object.keys(DataFieldsConfig).forEach((key: string) => {
      const list: LabeValue[] = DataFieldsConfig[key];
      const tList = list.filter((p) =>
        checkDataFieldsValue(dataFields, p.value)
      );
      tmpData[key] = tList.length > 0 ? true : false;
    });
    setShowIcon(tmpData);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => { 
    const { feature } = featuresTab[newValue];
    dispatch(selectedStation(JSON.parse(JSON.stringify(feature))));
    setTabIndex(newValue);
  };

  return (
    <MapProvider>
      <div style={{ width: '100%', height: `100%` }}>
        <ReactMapGL
          ref={mapRef}
          initialViewState={initialState}
          mapStyle='mapbox://styles/mapbox/light-v9'
          mapboxAccessToken={MAPBOX_TOKEN}
          onClick={handleClick}
          onLoad={onMapLoad}
          interactiveLayerIds={[INTERACTIVE_LAYER_ID]}
        >
          {popupLngLat && (
            <Popup
              longitude={popupLngLat.lng}
              latitude={popupLngLat.lat}
              closeButton={true}
              closeOnClick={false}
              onClose={() => {
                setPopupLngLat(null);
                dispatch(clearCurrentStation());
              }}
              style={{ minWidth: 550 }}
            >
              {featuresTab && featuresTab.length > 1 && !!featuresTab[0] ? (
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                      value={tabIndex}
                      onChange={handleTabChange}
                      aria-label='basic tabs example'
                    >
                      {featuresTab.map((item: any, index: number) => {
                        return (
                          <Tab
                            key={index}
                            label={item.station_id}
                            {...a11yProps(index)}
                          />
                        );
                      })}
                    </Tabs>
                  </Box>
                  {featuresTab.map((item: any, index: number) => {
                    return (
                      <CustomTabPanel
                        value={tabIndex}
                        index={index}
                        key={index}
                      >
                        <PopupComponent
                          showIcon={showIcon}
                          popupContent={item}
                          handleAdd={() => handleAdd(item)}
                        />
                      </CustomTabPanel>
                    );
                  })}
                </Box>
              ) : (
                popupContent && (
                  <PopupComponent
                    showIcon={showIcon}
                    popupContent={popupContent}
                    handleAdd={() => handleAdd(popupContent)}
                  />
                )
              )}
            </Popup>
          )}
          <Grid sx={{ position: 'absolute', right: 10, bottom: 25 }}>
            <Legend />
          </Grid>
          <Stations />
        </ReactMapGL>
      </div>
    </MapProvider>
  );
};

export default Map;
